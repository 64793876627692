import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { PageContextProps, PageInfoProps, PaginationContextProps, PublicationProps } from '../../types/pages'
import { GatsbyImageBasicProps } from '../../types/images'
import { SocialProps } from '../../types/blocks'
import { PostPreviewWithCategoriesProps } from '../../types/blog'
import { Layout, Seo } from '../../components/commons'
import { Box, Flex, Heading, Image, Link, Text } from '../../atoms'
import { SECTION_MARGIN, SECTION_TOP_PADDING } from '../../utils/constants'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import PaginatedPosts from '../../components/blog/commons/PaginatedPosts'

interface AuthorQuery {
  blogUtils: {
    paginationPreviousLabel: string
    paginationNextLabel: string
  }
  author: {
    meta: PublicationProps
    pageInfo: PageInfoProps
    name: string
    role: string
    description: string
    profilePicture: GatsbyImageBasicProps
    socials: Array<SocialProps>
  }
  postList: {
    nodes: PostPreviewWithCategoriesProps[]
  }
}

type PageContext = PageContextProps & PaginationContextProps

const Author: React.FC<PageProps<AuthorQuery, PageContext>> = ({ data, pageContext }) => {
  const { totalPages, currentPage, previousPagePath, nextPagePath } = pageContext
  const { blogUtils, author, postList } = data

  return (
    <Layout
      pageId={pageContext.id}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={author.pageInfo.hidePrefooter}
    >
      <Seo
        slug={author.pageInfo.slug}
        canonicalUrl={author.pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={author.meta}
        content={author.pageInfo.seo}
      />

      <Box as={'section'} pt={SECTION_TOP_PADDING}>
        <Grid>
          <Row middle={'xs'}>
            <Col xs={12} md={6}>
              <Box pr={[0, 0, 6]}>
                <Image
                  alt={author.profilePicture.alt ?? author.name}
                  title={author.profilePicture.title ?? author.name}
                  image={author.profilePicture.gatsbyImageData}
                />
              </Box>
            </Col>
            <Col xs={12} md={6}>
              <Box pl={[0, 0, 6]}>
                <Heading as={'h1'} type={'heading1'}>
                  {author.name}
                </Heading>
                {author.role && (
                  <Box mt={2}>
                    <Text color={'grey30'}>{author.role}</Text>
                  </Box>
                )}
                {author.description && (
                  <Box mt={3}>
                    <Text>{author.description}</Text>
                  </Box>
                )}

                {author.socials.length > 0 && (
                  <Flex mt={2} flexWrap={'wrap'} gap={2}>
                    {author.socials.map((social, idx) => (
                      <Box
                        key={social.id}
                        ml={idx > 0 ? 2 : undefined}
                        mr={idx + 1 < author.socials.length ? 2 : undefined}
                      >
                        <Link
                          href={social.url}
                          target={'_blank'}
                          rel={'noreferrer noopener'}
                          style={{ display: 'block' }}
                        >
                          <Image
                            alt={social.icon.alt || social.name}
                            src={social.icon.url}
                            width={social.icon.width}
                            height={social.icon.height}
                            draggable={'false'}
                          />
                        </Link>
                      </Box>
                    ))}
                  </Flex>
                )}
              </Box>
            </Col>
          </Row>

          <Box my={SECTION_MARGIN}>
            <PaginatedPosts
              posts={postList.nodes}
              prevLabel={blogUtils.paginationPreviousLabel}
              previousPagePath={previousPagePath}
              nextLabel={blogUtils.paginationNextLabel}
              nextPagePath={nextPagePath}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </Box>
        </Grid>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query AuthorQuery($id: String!, $locale: String!, $skip: Int!, $limit: Int!) {
    blogUtils: datoCmsBlog(locale: $locale) {
      paginationPreviousLabel
      paginationNextLabel
    }
    author: datoCmsAuthor(locale: $locale, id: { eq: $id }) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      name
      role
      description
      profilePicture {
        alt
        title
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
      socials {
        ...socialFragment
      }
    }
    postList: allDatoCmsPost(
      locale: $locale
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
      filter: {
        _allContentLocales: { elemMatch: { locale: { eq: $locale } } }
        authors: { elemMatch: { id: { eq: $id } } }
      }
    ) {
      nodes {
        ...postPreviewWithCategoriesFragment
      }
    }
  }
`

export default Author
